import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Card } from "react-bootstrap";
import { FaRegNewspaper } from "react-icons/fa";
import Hero from "../components/sections/Hero";

import browserRedirect from "../helpers/browserRedirect";
import withI18next from "../components/withI18next";
import { localizeLink } from "../components/Linki18n";

const Articles = ({ data, pageContext: { locale, originalPath }, t }) => {

  browserRedirect(locale);

  return (
    <>
    <SEO title={t("Articles")} lang={locale} description={t("meta_description")}/>

    <Layout originalPath={originalPath}>
        <Hero small/>
        <section className="section">
          <Container className="my-4">
            <h2>
              <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-2">
                <FaRegNewspaper/>
              </div>
              <span className="ml-3">{t("Articles")}</span>
            </h2>
            
            {data.allMarkdownRemark.edges.map(({node}) => (
              <a href={localizeLink(locale, `/articles/${node.fields.slug}`)} className="link-unstyled" key={node.fields.slug}>
                <Card className="card-profile card-lift--hover shadow my-4">
                  <Row className="p-3">
                    <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
                      <Img fluid={node.frontmatter.image.childImageSharp.fluid}/>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <h4>{node.frontmatter.title}</h4>
                      <p>{node.frontmatter.description}</p>
                      <span>{t('articles_author_date', {author: node.frontmatter.author, date: new Date(node.frontmatter.date)})}</span>
                    </Col>
                  </Row>
                </Card>
              </a>
            ))}

          </Container>
        </section>
    </Layout>
    </>
  );
};

export default withI18next({ ns: "common" })(Articles);

export const query = graphql`
  query ArticlesQuery($locale: String!){
    allMarkdownRemark(
      filter: {fields: {locale: {eq: $locale}}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            author
            date(formatString: "MMMM DD, YYYY")
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;


